














































































































































import Vue from "vue";
import stateManager from "@/services/stateManager";
import userManager from "@/services/userManager";
import apiClient from "@/services/apiClient";
import configProvider from "@/services/configProvider";
import CONSTANTS from "@/constant/index";

Vue.filter("packageImageUrl", (item) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/packages/${item.package_id}/images/${item.id}`;
  return url;
});

Vue.filter("virtualPackageImageUrl", (item) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/virtual_packages/${item.virtual_package_id}/images/${item.id}`;
  return url;
});

Vue.filter("liquidationSlipImageUrl", (item) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/liquidation_slips/${item.liquidation_slip_id}/images/${item.id}`;
  return url;
});

Vue.filter("discussionImageUrl", (item) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/discussions/${item.discussion_id}/images/${item.id}`;
  return url;
});

Vue.filter("discussionMessageImageUrl", (item, discusionId) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/discussions/${discusionId}/messages/${
    item.discussion_message_id
  }/images/${item.id}`;
  return url;
});

Vue.filter("conversationImageUrl", (item) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/conversations/${item.conversation_id}/images/${item.id}`;
  return url;
});

Vue.filter("conversationMessageImageUrl", (item, discusionId) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/conversations/${discusionId}/messages/${
    item.conversation_message_id
  }/images/${item.id}`;
  return url;
});

Vue.filter("suggestionImageUrl", (item) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/suggestions/${item.suggestion_id}/images/${item.id}`;
  return url;
});

Vue.filter("policySpendingImageUrl", (item) => {
  if (item.data) {
    return item.data;
  }
  const url = `${configProvider.get("BASE_URL")}/api/policies/${item.policy_id}/spending_images/${item.id}`;
  return url;
});

export default Vue.extend({
  data() {
    return {
      CONSTANTS,
      userManager,
      self: this,
      mini: true,
      drawer: false,
      language: "",
      items: [
        {
          title: "Trang chủ",
          icon: "mdi-home",
          to: "/app/index/business",
        },
        {
          title: this.$t("USERS"),
          icon: "mdi-account-group",
          to: "/app/users",
          condition() {
            return userManager.checkRole("t");
          },
        },
        {
          title: this.$t("CUSTOMERS"),
          icon: "mdi-account",
          to: "/app/customers",
          condition() {
            return userManager.checkRole(["t", "kd", "kt"]);
          },
        },
        {
          title: "Bảng giá",
          icon: "mdi-file-table-box-multiple-outline",
          to: "/app/pricing_items",
          condition() {
            return userManager.checkRole(["t", "kd", "kt"]);
          },
        },
        {
          title: this.$t("VIRTUAL_PACKAGES"),
          icon: "mdi-cash-multiple",
          to: "/app/virtual_packages",
          condition() {
            return userManager.checkRole(["kt", "kd"]) && !userManager.getImpersonateUserInfo();
          },
        },
        {
          title: this.$t("PACKAGES_ALL"),
          icon: "mdi-package-variant",
          to: "/app/packages",
          condition() {
            return userManager.checkRole(["k", "xnk", "cs", "kt", "kd", "htkd"]);
          },
        },
        {
          title: this.$t("PACKAGES_LOAD_PENDING"),
          icon: "mdi-package-variant-closed",
          to: "/app/packages_load_pending",
          condition() {
            return userManager.checkRole(["k", "xnk", "cs", "kt", "kd", "htkd"]);
          },
        },
        {
          title: this.$t("PACKAGES_MONEY"),
          icon: "mdi-currency-usd",
          to: "/app/packages_money",
          condition() {
            return userManager.checkRole(["kt", "kd"]);
          },
        },
        {
          title: this.$t("PACKAGES_REGISTER"),
          icon: "mdi-registered-trademark",
          to: "/app/packages_register",
          condition() {
            return userManager.checkRole(["xnk", "cs", "kd", "htkd"]);
          },
        },
        {
          title: this.$t("PACKAGES_VN"),
          icon: "mdi-home-circle",
          to: "/app/packages_vn",
          condition() {
            return userManager.checkRole(["kt", "kd"]) && !userManager.getImpersonateUserInfo();
          },
        },
        {
          title: this.$t("TRUCKS_LOADED"),
          icon: "mdi-truck-check",
          to: "/app/trucks",
          condition() {
            return userManager.checkRole(["k", "xnk", "cs", "kt", "kd", "htkd", "kvn"]);
          },
        },
        {
          title: this.$t("TRUCKS_EXPORT"),
          icon: "mdi-swap-vertical-bold",
          to: "/app/trucks_export",
          condition() {
            return userManager.checkRole(["xnk", "kt", "cs"]);
          },
        },
        {
          title: "Phiếu thanh lý",
          icon: "mdi-order-bool-ascending",
          to: "/app/liquidation_slips",
          condition() {
            return userManager.checkRole(["kt", "kd", "kvn"]);
          },
        },
        {
          title: "Hỏi hàng xưởng",
          icon: "mdi-chat",
          to: "/app/discussions",
          condition() {
            return userManager.checkRole(["kt", "kd", "gd"]);
          },
        },
        {
          title: "Thống kê",
          icon: "mdi-trending-up",
          to: "/app/reports",
        },
        {
          title: "Hóa đơn",
          icon: "mdi-paper-roll ",
          to: "/app/bills",
          condition() {
            return !userManager.getImpersonateUserInfo() && userManager.checkRole(["kt", "kd"]);
          },
        },
        {
          title: "Đề xuất",
          icon: "mdi-expand-all-outline",
          to: "/app/suggestions",
          condition() {
            return !userManager.getImpersonateUserInfo();
          },
        },
        {
          title: "Chính sách",
          icon: "mdi-archive-edit-outline",
          to: "/app/policies",
          condition() {
            return userManager.checkRole(["kd", "cs", "ktt", "t"]) && !userManager.getImpersonateUserInfo();
          },
        },
        {
          title: "Hộp thư",
          icon: "mdi-inbox",
          to: "/app/conversations",
        },
      ],
      notifications: [],
      languageMap: {
        vn: {
          text: "Tiếng Việt",
        },
        cn: {
          text: "中文",
        },
      },
      userInfo: {},
      availableRoleMap: {
        k: this.$t("ROLE_K"),
        xnk: this.$t("ROLE_XNK"),
        kd: this.$t("ROLE_KD"),
        t: this.$t("ROLE_T"),
        kt: this.$t("ROLE_KT"),
        td: this.$t("ROLE_TD"),
        gd: this.$t("ROLE_GD"),
        htkd: this.$t("ROLE_HTKD"),
        cs: "Chính sách",
        kvn: "Kho VN",
        ktt: "Kế toán trưởng",
        mkt: "Marketing",
        tpkd: "Trưởng phòng kinh doanh",
        gdkd: "Giám đốc kinh doanh",
      },
      count: 0,
      kdId: "",
      kds: [],
    };
  },
  computed: {
    languages() {
      const items = [];
      for (const k in this.languageMap) {
        const language = this.languageMap[k];
        items.push({
          text: language.text,
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.notification();
      });
    },
  },
  methods: {
    async logout() {
      await apiClient.authLogout();
      userManager.deleteAccessToken();
      this.$router.push("/login");
    },
    updateLanguage() {
      stateManager.set("language", this.language);
      location.reload();
    },
    notification(active = false) {
      this.getNotificationByUser(active);
    },
    async getNotificationByUser(active) {
      if (active) {
        this.drawer = true;
      }
      const userInfos = JSON.parse(localStorage.getItem("userInfo"));
      const res = await apiClient.getNotificationByUser(userInfos.sub);
      if (res) {
        this.notifications = res.notifications.filter((item) => {
          return item.pivot.status_notification === 0;
        });
        this.notifications = this.getUnique(this.notifications, "id");
        this.count = this.notifications.length;
      }
    },
    async changeStatusNotification(data) {
      await this.$router.push({
        path: `/app/index/notification/${data.id}/detail`,
      });
      const res = await apiClient.changeStatusNotification(data.pivot);
      if (res) {
        await this.getNotificationByUser(false);
      }
    },
    getUnique(arr, comp) {
      const unique = arr
        .map((e) => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e])
        .map((e) => arr[e]);
      return unique;
    },
    onKdIdChanged() {
      const value = this.kdId;
      if (!value) {
        userManager.deleteImpersonateUserInfo();
      } else {
        userManager.setImpersonateUserInfo({ id: value });
      }
      location.reload();
    },
  },
  async created() {
    this.notification();
    this.language = this.$i18n.locale;
    this.userInfo = userManager.getUserInfo();
    if (userManager.checkRole(["tpkd"], true)) {
      const impersonateUserInfo = userManager.getImpersonateUserInfo();
      if (impersonateUserInfo) {
        this.kdId = impersonateUserInfo.id;
      }
      const items = await apiClient.getStaffByUser(this.userInfo.sub);
      this.kds = [
        { id: "", name: "CÁ NHÂN" },
        { id: "@all", name: "TẤT CẢ" },
      ].concat(items);
    }
  },
});
